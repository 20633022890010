import CircularProgress from '@material-ui/core/CircularProgress'
import * as React from 'react'
import { Form } from 'react-bootstrap'
import { getBlockClass } from '../../../utilities/helpers'
import './styles.scss'

type MultiFactorUIProps = {
  submitOTP: (otp?: string) => void
  requestOTP: () => void,
  isFetchingLogin: boolean,
  error?: any
}

const ROOT_CLASS = 'mfa-ui'

export const MultiFactorAuthUI = (props: MultiFactorUIProps) => {
  const [otp, setOTP] = React.useState<string>()
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.submitOTP(otp)
        }}
        className={getBlockClass(ROOT_CLASS, 'mfa-form')}
      >
        <input
          required
          maxLength={6}
          placeholder="One-Time Passcode"
          autoFocus
          onChange={(e) => setOTP(e.target.value)}
        />
        <div className={getBlockClass(ROOT_CLASS, 'button-container')}>
          <button
            type="button"
            onClick={() => props.requestOTP()}
            className={getBlockClass(ROOT_CLASS, 'secondary-button')}
          >
            Resend OTP
          </button>
          <button
            type="submit"
            className={getBlockClass(ROOT_CLASS, 'primary-button')}
          >
          {
            !props.isFetchingLogin ? "Verify" : <CircularProgress style={{color: 'white', padding: "4px"}}/>
          }
          </button>
        </div>
      </form>
      {props.error && (
        <Form.Text className="app-error-message">{props.error}</Form.Text>
      )}
    </>
  )
}
