const FirebaseQAConfig = {
  authDomain: 'back-office-client-qa.firebaseapp.com',
  projectId: 'back-office-client-qa',
  storageBucket: 'back-office-client-qa.firebasestorage.app',
  messagingSenderId: '361467551000',
  appId: '1:361467551000:web:ae6da25715b08f5028126d',
  measurementId: 'G-95245PC5PC',
}

const FirebaseUATConfig = {
  authDomain: 'back-office-client-uat.firebaseapp.com',
  projectId: 'back-office-client-uat',
  storageBucket: 'back-office-client-uat.firebasestorage.app',
  messagingSenderId: '147829521686',
  appId: '1:147829521686:web:d4115d8f86337477f2e437',
}

const FirebasePRODConfig = {
  authDomain: 'back-office-client-prod.firebaseapp.com',
  projectId: 'back-office-client-prod',
  storageBucket: 'back-office-client-prod.firebasestorage.app',
  messagingSenderId: '734560511052',
  appId: '1:734560511052:web:33a5057944563938c600e9',
}

export const getConfigForEnv = () => {
  let configForEnv
  switch (process.env.FIREBASE_ENV) {
    case 'UAT':
      configForEnv = FirebaseUATConfig
      break
    case 'PROD':
      configForEnv = FirebasePRODConfig
      break
    case 'QA':
    default:
      configForEnv = FirebaseQAConfig
      break
  }
  return {
    ...configForEnv,
    apiKey: process.env.FIREBASE_API_KEY,
  }
}
