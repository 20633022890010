import { inject, observer } from 'mobx-react'
import * as React from 'react'
import config from '../../../config'
import { useRemoteConfig } from '../../../context/RemoteConfigContext'
import { useFetch } from '../../../hooks/use-fetch'
import { LandingPageUI } from '../landing-page/landing-page-ui'
import { LoginPanelUI } from './login-panel-ui'

type LoginPageContainer = {
  authStore: any
}

const SSO_URL = `${config.SERVER_BASE_URL}/v1/auth/sso-login`

export const LoginPageContainer = (props: LoginPageContainer) => {
  const { remoteConfig } = useRemoteConfig()
  const { legacyLoginEnabled, ssoAuthEnabled } = remoteConfig
  const { authStore } = props
  const ssoUseFetch = useFetch<{ url: string }>()

  const submitCredentials = (email?: string, password?: string) => {
    if (email && password) {
      authStore.loginWithPassword(email, password, props)
    }
  }
  const requestSso = () => {
    ssoUseFetch.fetchUrl(SSO_URL, 'POST')
  }

  React.useEffect(() => {
    if (ssoUseFetch.data && !ssoUseFetch.error) {
      window.location.href = ssoUseFetch.data.url
    }
  }, [ssoUseFetch.data, ssoUseFetch.error])

  return (
    <LandingPageUI>
      <LoginPanelUI
        isFetchingLogin={authStore.requestLoading}
        displayLegacyLogin={legacyLoginEnabled}
        displaySso={ssoAuthEnabled}
        submitCredentials={submitCredentials}
        requestSso={requestSso}
        isFetchingSSO={ssoUseFetch.isLoading}
        error={ssoUseFetch.error ?? authStore.passwordAuthErrorMessage}
      />
    </LandingPageUI>
  )
}

export const LoginPageHOC = inject('authStore')(
  observer((authStore: { authStore: any }) => LoginPageContainer(authStore))
)
