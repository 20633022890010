import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { Form } from 'react-bootstrap'
import { getBlockClass } from '../../../utilities/helpers'
import { LoadingDots } from '../../CommonComponents/loading-dots/loading-dots-ui'
import { MicrosoftLogo } from '../landing-page/assets/microsoft-logo'
import './styles.scss'

const ROOT_CLASS = 'login-panel'

type LoginPanelUIProps = {
  isFetchingSSO: boolean
  isFetchingLogin: boolean,
  submitCredentials: (email?: string, password?: string) => void
  requestSso: () => void
  displayLegacyLogin: boolean
  displaySso: boolean
  error: any
}

export const LoginPanelUI = (props: LoginPanelUIProps) => {
  const { error, displayLegacyLogin, displaySso } = props
  const [password, setPassword] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>()
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.submitCredentials(email, password)
        }}
        className={getBlockClass(ROOT_CLASS, 'login-form')}
      >
        {displayLegacyLogin && (
          <>
            <input
              type="email"
              placeholder="Email Address"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Password" 
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />

            <button
              type="submit"
              className={getBlockClass(ROOT_CLASS, 'login-button')}
            >
              {
                !props.isFetchingLogin ? "Login" : <CircularProgress style={{color: 'white', padding: "4px"}}/>
              }
            </button>
          </>
        )}
        {displaySso && (
          <button
            type="button"
            className={getBlockClass(ROOT_CLASS, 'sso-button')}
            onClick={() => props.requestSso()}
          >
            <MicrosoftLogo />
            {props.isFetchingSSO ? (
              <LoadingDots id="loading-dots" />
            ) : (
              'Sign in with Microsoft'
            )}
          </button>
        )}
      </form>
      {error && (
        <Form.Text className="app-error-message">{error}</Form.Text>
      )}
    </>
  )
}
