import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'

import { Provider } from 'mobx-react'
import Routes from './routes'

import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthFreshDesk } from './components/FreshDeskWidget/freshdesk-widget'
import { BannerProvider } from './context/BannerContext'
import RemoteConfigProvider from './context/RemoteConfigContext'
import { SubAccountProvider } from './context/SubAccountContext'
import './fonts/MyriadProRegular.otf'
import './index.css'
import { getSentryConfig } from './sentry/config'
import * as serviceWorker from './serviceWorker'
import { rootStore } from './store/RootStore'
import AppLogout from './utilities/AppLogout'

try {
  const config = getSentryConfig()
  Sentry.init(config)
} catch (e) {
  console.log('Could not start Sentry', e)
}

const Root = (props) => (
  <AppLogout>
    <HashRouter>
      <Routes />
    </HashRouter>
    <AuthFreshDesk />
  </AppLogout>
)

ReactDOM.render(
  <React.StrictMode>
    <RemoteConfigProvider>
      <BannerProvider>
        <SubAccountProvider>
          <Provider
            rootStore={rootStore}
            authStore={rootStore.authStore}
            playerStore={rootStore.playerStore}
            claimStore={rootStore.claimStore}
            settingsStore={rootStore.settingsStore}
            miscellaneousStore={rootStore.miscellaneousStore}
            annuitiesStore={rootStore.annuitiesStore}
            charitableGamingStore={rootStore.charitableGamingStore}
            paymentSearchStore={rootStore.paymentSearchStore}
          >
            <Root />
          </Provider>
        </SubAccountProvider>
      </BannerProvider>
    </RemoteConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
