import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MultiFactorAuthUI } from './mfa-panel-ui'
import { LandingPageUI } from '../landing-page/landing-page-ui'

type MultiFactorAuthContainerProps = {
  authStore: any
}

const MultiFactorAuthContainer = (props: MultiFactorAuthContainerProps) => {
  const { authStore } = props

  const submitOTP = (otp?: string) => {
    if (!otp) {
      return
    }
    authStore.loginWithTOTP(otp, props)
  }

  const requestOTP = () => {
    authStore.requestOTP()
  }
  return (
    <LandingPageUI>
      <MultiFactorAuthUI
        submitOTP={submitOTP}
        requestOTP={requestOTP}
        error={authStore.otpAuthErrorMessage}
        isFetchingLogin={authStore.isRequestLoading}
      />
    </LandingPageUI>
  )
}

export const MultiFactorAuthHOC = inject('authStore')(
  observer((authStore: { authStore: any }) =>
    MultiFactorAuthContainer(authStore)
  )
)
