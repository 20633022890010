import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { getConfigForEnv } from './configs'
const app = initializeApp(getConfigForEnv())
const RemoteConfig = getRemoteConfig(app)
// Setting this to 0 for lower environments, but an hour for production.
RemoteConfig.settings.minimumFetchIntervalMillis =
  process.env.FIREBASE_ENV != 'PROD' ? 0 : 3600

const FirebaseService = {
  RemoteConfig,
}

export default FirebaseService
