import * as React from 'react'

export const MicrosoftLogo = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ms-symbollockup_mssymbol_19 1">
      <path
        id="Vector"
        d="M15.2382 1.52344H1.52393V15.2377H15.2382V1.52344Z"
        fill="#F25022"
      />
      <path
        id="Vector_2"
        d="M15.2382 16.7617H1.52393V30.476H15.2382V16.7617Z"
        fill="#00A4EF"
      />
      <path
        id="Vector_3"
        d="M30.476 1.52344H16.7617V15.2377H30.476V1.52344Z"
        fill="#7FBA00"
      />
      <path
        id="Vector_4"
        d="M30.476 16.7617H16.7617V30.476H30.476V16.7617Z"
        fill="#FFB900"
      />
    </g>
  </svg>
)
